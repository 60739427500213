@import url(./reference.css);

:root {
  /* Blue */
  --color-blue-lightest: var(--color-blue-2);
  --color-blue-lighter: var(--color-blue-4);
  --color-blue-normal: var(--color-blue-6);
  --color-blue-darker: var(--color-blue-8);
  --color-blue-darkest: var(--color-blue-9);

  /* Orange */
  --color-orange-lightest: var(--color-orange-2);
  --color-orange-lighter: var(--color-orange-4);
  --color-orange-normal: var(--color-orange-6);
  --color-orange-darker: var(--color-orange-7);
  --color-orange-darkest: var(--color-orange-8);

  /* Turquoise */
  --color-turquoise-lightest: var(--color-turquoise-2);
  --color-turquoise-lighter: var(--color-turquoise-4);
  --color-turquoise-normal: var(--color-turquoise-6);
  --color-turquoise-darker: var(--color-turquoise-7);
  --color-turquoise-darkest: var(--color-turquoise-8);

  /* Red */
  --color-red-lightest: var(--color-red-2);
  --color-red-lighter: var(--color-red-4);
  --color-red-normal: var(--color-red-6);
  --color-red-darker: var(--color-red-7);
  --color-red-darkest: var(--color-red-8);

  /* Green */
  --color-green-lightest: var(--color-green-2);
  --color-green-lighter: var(--color-green-4);
  --color-green-normal: var(--color-green-6);
  --color-green-darker: var(--color-green-7);
  --color-green-darkest: var(--color-green-8);

  /* Alpha */
  --color-alpha-neutral: #000000;
}
