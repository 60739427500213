@import url(./color/usecase.css);
@import url('./typography/token.css');

:root {
  /**
   * Spacing
   */
  --spacing-1: 2px;
  --spacing-2: 4px;
  --spacing-3: 8px;
  --spacing-4: 12px;
  --spacing-5: 16px;
  --spacing-6: 24px;
  --spacing-7: 32px;
  --spacing-8: 48px;
  --spacing-9: 64px;

  /**
   * Primary Colors
   */
  --color-primary-1: #f0f7ff;
  --color-primary-2: #cadefa;
  --color-primary-5: #4673d4; /* --color-blue-7 と同等 */
  --color-primary-6: #2352c8; /* --color-blue-8 --color-blue-darker --color-brand-primary-darker --text-link と同等 */
  --color-primary-7: #1337a1; /* --color-blue-9 --color-blue-darkest --color-brand-primary-darkest と同等 */

  /**
   * Info Colors
   */
  --color-info-1: #ebf5f5;
  --color-info-2: #b9e6eb;
  --color-info-5: #32aab9;
  --color-info-6: #148291;
  --color-info-7: #28646e;

  /**
   * Success Colors
   */
  --color-success-1: #e4f5df;
  --color-success-2: #c8f0be;
  --color-success-5: #78c86e;
  --color-success-6: #2cad28;
  --color-success-7: #016903; /* --color-green-8 --color-green-darkest --color-status-success-darkest と同等 */

  /**
   * Warning Colors
   */
  --color-warning-1: #fff5e6;
  --color-warning-2: #ffd2aa;
  --color-warning-5: #ffa04b;
  --color-warning-6: #fa7d19;
  --color-warning-7: #d25a0f;

  /**
   * Danger Colors
   */
  --color-danger-1: #ffebe6;
  --color-danger-2: #ffc3be;
  --color-danger-5: #dc5a5a;
  --color-danger-6: #dc3c37;
  --color-danger-7: #b93232;

  /**
   * Transparent Colors
   */
  --color-transparent-dark: rgba(0, 0, 0, 0.44);
  --color-transparent-light: rgba(255, 255, 255, 0.67);

  /**
   * Colors
   */
  --color-dark-based-text: var(
    --color-neutral-1
  ); /* 白抜き表示用テキストカラー（背景色がcolor-6~10の場合に用いる） */
  --color-base-background: var(--color-neutral-0); /* UI背景色（白） */
  --color-page-background: var(--color-neutral-1); /* ページ用背景色 */
  --color-disabled-background: var(--color-neutral-2); /* 無効時のUI要素の背景色 */
  --color-disabled-base-background: var(
    --color-neutral-5
  ); /* 無効時のUI要素の背景色（for Primary/Warning/Danger） */
  --color-overlay-background: var(
    --color-transparent-dark
  ); /* 背景を明るく透過して表示する背景色。Submitting/Loading中に用いる */
  --color-non-financial-item-cell: #fff5e6; /* 非財務科目の背景色 */
  --color-highlight-background: var(--color-warning-2); /* ハイライト時の背景色 */
  --color-highlight-text: var(--color-warning-5); /* ハイライト適用時のUIテキストカラー */
  --color-element-border: var(--color-neutral-5); /* UI要素の外枠に付けるボーダーカラー */
  --color-primary: var(--color-brand-primary-darker);
  --color-primary--hover: var(--color-blue-7);
  --color-primary--active: var(--color-brand-primary-darkest);
  --color-secondary-background: var(--color-primary-1);
  --color-info: var(--color-info-6);
  --color-info--hover: var(--color-info-5);
  --color-info--active: var(--color-info-7);
  --color-success: var(--color-success-6);
  --color-success--hover: var(--color-success-5);
  --color-success--active: var(--color-status-success-darkest);
  --color-warning: var(--color-warning-6);
  --color-warning--hover: var(--color-warning-5);
  --color-warning--active: var(--color-warning-7);
  --color-danger: var(--color-danger-6);
  --color-danger--hover: var(--color-danger-5);
  --color-danger--active: var(--color-danger-7);

  /**
   * Border
   */
  --border-width-s: 1px;
  --border-width-m: 2px;
  --border-radius-s: 2px;
  --border-radius-m: 4px;

  /**
   * Shadow
   */
  --shadow-element-focus: 0 0 4px var(--color-blue-7);
  --shadow-element-inner: inset 4px 4px 4px rgba(0, 0, 0, 0.17);
  --shadow-element-floating: 0 0 12px rgba(0, 0, 0, 0.17);
  --shadow-floating-container: 0 0 4px rgba(23, 23, 37, 0.16);

  /**
   * Fonts
   */
  /* @deprecated --font-family を利用すること */
  --font-family-en:
    -apple-system, 'blinkmacsystemfont', 'Segoe UI', 'Helvetica Neue', 'arial', sans-serif;
  /* 日本語フォントの設定: Macはヒラギノ角ゴシックで表示。Winではsystem-ui（Yu Gothic UI）を用いる */
  /* @deprecated --font-family を利用すること */
  --font-family-jp:
    'Hiragino Sans', 'ヒラギノ角ゴシック', 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3',
    system-ui, '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック', 'Yu Gothic', sans-serif;

  /**
   * Overlay
   */
  --color-processing-overlay: var(--color-transparent-light);
}

/**
  * ::backdrop 擬似要素で :root で定義した CSS 変数を参照できないため、こちらに定義する
  */
::backdrop {
  --color-transparent-dark: rgba(0, 0, 0, 0.44);
}
