@import url(./token.css);

:root {
  /* Brand */
  --color-brand-primary-lightest: var(--color-blue-lightest);
  --color-brand-primary-lighter: var(--color-blue-lighter);
  --color-brand-primary-normal: var(--color-blue-normal);
  --color-brand-primary-darker: var(--color-blue-darker);
  --color-brand-primary-darkest: var(--color-blue-darkest);

  /* Action / Positive */
  --color-action-positive-lightest: var(--color-blue-lightest);
  --color-action-positive-lighter: var(--color-blue-lighter);
  --color-action-positive-normal: var(--color-blue-normal);
  --color-action-positive-darker: var(--color-blue-darker);
  --color-action-positive-darkest: var(--color-blue-darkest);

  /* Action / Negative */
  --color-action-negative-lightest: var(--color-red-lightest);
  --color-action-negative-lighter: var(--color-red-lighter);
  --color-action-negative-normal: var(--color-red-normal);
  --color-action-negative-darker: var(--color-red-darker);
  --color-action-negative-darkest: var(--color-red-darkest);

  /* Status / Active */
  --color-status-active-lightest: var(--color-blue-lightest);
  --color-status-active-lighter: var(--color-blue-lighter);
  --color-status-active-normal: var(--color-blue-normal);
  --color-status-active-darker: var(--color-blue-darker);
  --color-status-active-darkest: var(--color-blue-darkest);

  /* Status / Warning */
  --color-status-warning-lightest: var(--color-orange-lightest);
  --color-status-warning-lighter: var(--color-orange-lighter);
  --color-status-warning-normal: var(--color-orange-normal);
  --color-status-warning-darker: var(--color-orange-darker);
  --color-status-warning-darkest: var(--color-orange-darkest);

  /* Status / Info */
  --color-status-info-lightest: var(--color-turquoise-lightest);
  --color-status-info-lighter: var(--color-turquoise-lighter);
  --color-status-info-normal: var(--color-turquoise-normal);
  --color-status-info-darker: var(--color-turquoise-darker);
  --color-status-info-darkest: var(--color-turquoise-darkest);

  /* Status / Error */
  --color-status-error-lightest: var(--color-red-lightest);
  --color-status-error-lighter: var(--color-red-lighter);
  --color-status-error-normal: var(--color-red-normal);
  --color-status-error-darker: var(--color-red-darker);
  --color-status-error-darkest: var(--color-red-darkest);

  /* Status / Success */
  --color-status-success-lightest: var(--color-green-lightest);
  --color-status-success-lighter: var(--color-green-lighter);
  --color-status-success-normal: var(--color-green-normal);
  --color-status-success-darker: var(--color-green-darker);
  --color-status-success-darkest: var(--color-green-darkest);

  /* Status / Disabled */
  --color-status-disabled-lighter: var(--color-neutral-2);
  --color-status-disabled-normal: var(--color-neutral-5);
}
