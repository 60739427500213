@import '../../../node_modules/normalize.css/normalize.css';
@import './variables.css';

html,
body {
  -webkit-font-smoothing: antialiased;
}

html {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  line-height: 1;
}

body {
  position: relative;
}

a {
  cursor: pointer;
  text-decoration: none;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

ul,
ol,
dl {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

th {
  text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
dd {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
  border: none;
}

p {
  line-height: 1.5;
}

button {
  padding: unset;
  text-align: unset;
  border: unset;
  outline: unset;
}

th,
td {
  padding: 0;
}
