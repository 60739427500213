:root {
  /* Blue */
  --color-blue-1: #f8fafd;
  --color-blue-2: #eef5ff;
  --color-blue-3: #d5e4fb;
  --color-blue-4: #bdd4f7;
  --color-blue-5: #90ade3;
  --color-blue-6: #7295de;
  --color-blue-7: #4673d4;
  --color-blue-8: #2352c8;
  --color-blue-9: #1337a1;
  --color-blue-10: #062073;

  /* Orange */
  --color-orange-1: #fef9f3;
  --color-orange-2: #fde9d0;
  --color-orange-3: #ffdebf;
  --color-orange-4: #fec895;
  --color-orange-5: #f19657;
  --color-orange-6: #e77623;
  --color-orange-7: #c3540a;
  --color-orange-8: #9a3f05;
  --color-orange-9: #703416;
  --color-orange-10: #422b06;

  /* Turquoise */
  --color-turquoise-1: #f5fefe;
  --color-turquoise-2: #ecfafa;
  --color-turquoise-3: #bfebef;
  --color-turquoise-4: #98dde5;
  --color-turquoise-5: #5cb9c6;
  --color-turquoise-6: #29a4b3;
  --color-turquoise-7: #078292;
  --color-turquoise-8: #026572;
  --color-turquoise-9: #104c56;
  --color-turquoise-10: #0c343a;

  /* Red */
  --color-red-1: #fdf5f2;
  --color-red-2: #ffede9;
  --color-red-3: #ffdbd4;
  --color-red-4: #ffc4b9;
  --color-red-5: #f78d85;
  --color-red-6: #ee6e66;
  --color-red-7: #d3403a;
  --color-red-8: #ab2327;
  --color-red-9: #7f2020;
  --color-red-10: #511414;

  /* Green */
  --color-green-1: #f7fef5;
  --color-green-2: #eafbe5;
  --color-green-3: #cdecc6;
  --color-green-4: #afdfa3;
  --color-green-5: #6fbe65;
  --color-green-6: #37ac32;
  --color-green-7: #108a10;
  --color-green-8: #016903;
  --color-green-9: #174e15;
  --color-green-10: #133412;

  /* Neural */
  --color-neutral-0: #ffffff;
  --color-neutral-1: #fafafb;
  --color-neutral-2: #f1f1f5;
  --color-neutral-3: #e2e2ea;
  --color-neutral-4: #d5d5dc;
  --color-neutral-5: #b5b5be;
  --color-neutral-6: #9090a1;
  --color-neutral-7: #6c6c7f;
  --color-neutral-8: #50505d;
  --color-neutral-9: #3f3f4a;
  --color-neutral-10: #171725;
}
