@font-face {
  font-family: number;
  src:
    local('-apple-system'), local('blinkmacsystemfont'), local('Segoe UI'), local('Helvetica Neue'),
    local('arial'), local(sans-serif);
  /* %, 0-9, comma, hyphen, period(小数点) */
  unicode-range: U+0025, U+30-39, U+2C-2E;
}

:root {
  --font-size-xl: 24px;
  --font-size-l: 18px;
  --font-size-m: 16px;
  --font-size-s: 14px;
  --font-size-xs: 12px;
  --font-size-xxs: 10px;

  --line-height-100: 1;
  --line-height-140: 1.4;

  --font-weight-w4: 400;
  --font-weight-w6: 600;

  /* 数字のみ font-face で指定したものを使用 */
  /* Macはヒラギノ角ゴシックを用い、Winではsystem-ui（Yu Gothic UI）を用いる */
  --font-family:
    number, 'Hiragino Sans', 'ヒラギノ角ゴシック', 'Hiragino Kaku Gothic ProN',
    'ヒラギノ角ゴ ProN W3', system-ui, '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック',
    'Yu Gothic', sans-serif;
}
