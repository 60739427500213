@import 'reference.css';

:root {
  /* Body: 文章に利用する */
  --font-body-xl: var(--font-weight-w4) var(--font-size-xl) / var(--line-height-140)
    var(--font-family);
  --font-body-l: var(--font-weight-w4) var(--font-size-l) / var(--line-height-140)
    var(--font-family);
  --font-body-m: var(--font-weight-w4) var(--font-size-m) / var(--line-height-140)
    var(--font-family);
  --font-body-s: var(--font-weight-w4) var(--font-size-s) / var(--line-height-140)
    var(--font-family);
  --font-body-xs: var(--font-weight-w4) var(--font-size-xs) / var(--line-height-140)
    var(--font-family);
  --font-body-xxs: var(--font-weight-w4) var(--font-size-xxs) / var(--line-height-140)
    var(--font-family);

  --font-body-xl-bold: var(--font-weight-w6) var(--font-size-xl) / var(--line-height-140)
    var(--font-family);
  --font-body-l-bold: var(--font-weight-w6) var(--font-size-l) / var(--line-height-140)
    var(--font-family);
  --font-body-m-bold: var(--font-weight-w6) var(--font-size-m) / var(--line-height-140)
    var(--font-family);
  --font-body-s-bold: var(--font-weight-w6) var(--font-size-s) / var(--line-height-140)
    var(--font-family);
  --font-body-xs-bold: var(--font-weight-w6) var(--font-size-xs) / var(--line-height-140)
    var(--font-family);
  --font-body-xxs-bold: var(--font-weight-w6) var(--font-size-xxs) / var(--line-height-140)
    var(--font-family);

  /* Headline: 見出しに利用する */
  --font-headline-l: var(--font-weight-w6) var(--font-size-l) / var(--line-height-140)
    var(--font-family);
  --font-headline-m: var(--font-weight-w6) var(--font-size-m) / var(--line-height-140)
    var(--font-family);
  --font-headline-s: var(--font-weight-w6) var(--font-size-s) / var(--line-height-140)
    var(--font-family);
  --font-headline-xs: var(--font-weight-w6) var(--font-size-xs) / var(--line-height-140)
    var(--font-family);
  --font-headline-xxs: var(--font-weight-w6) var(--font-size-xxs) / var(--line-height-140)
    var(--font-family);

  /* Label: ラベルに利用する */
  --font-label-l: var(--font-weight-w6) var(--font-size-l) / var(--line-height-100)
    var(--font-family);
  --font-label-m: var(--font-weight-w6) var(--font-size-m) / var(--line-height-100)
    var(--font-family);
  --font-label-s: var(--font-weight-w6) var(--font-size-s) / var(--line-height-100)
    var(--font-family);
  --font-label-xs: var(--font-weight-w6) var(--font-size-xs) / var(--line-height-100)
    var(--font-family);
  --font-label-xxs: var(--font-weight-w6) var(--font-size-xxs) / var(--line-height-100)
    var(--font-family);
}
