@import url(./semantic-token.css);

:root {
  /* Border: 境界線の色に利用する */
  --color-border-soft: var(--color-neutral-2);
  --color-border-medium: var(--color-neutral-4);
  --color-border-hard: var(--color-neutral-5);

  /* Surface: 背景色に利用する */
  --color-surface-normal: var(--color-neutral-0);
  --color-surface-secondary: var(--color-neutral-2);
  --color-surface-active: var(--color-status-active-lightest);
  --color-surface-info: var(--color-status-info-lightest);
  --color-surface-success: var(--color-status-success-lightest);
  --color-surface-warning: var(--color-status-warning-lightest);
  --color-surface-error: var(--color-status-error-lightest);
  --color-surface-disabled: var(--color-status-disabled-normal);

  /* Text: 文字の色に利用する */
  --color-text-normal: var(--color-neutral-9); /* UIテキストカラー */
  --color-text-normal-inverse: var(--color-neutral-0);
  --color-text-annotation: var(--color-neutral-7);
  --color-text-link: var(--color-brand-primary-darker);
  --color-text-placeholder: var(--color-neutral-5);
  --color-text-active: var(--color-status-active-darkest);
  --color-text-info: var(--color-status-info-darkest);
  --color-text-success: var(--color-status-success-darker);
  --color-text-warning: var(--color-status-warning-darker);
  --color-text-error: var(--color-status-error-darker); /* プレースホルダー用のUIテキストカラー */
  --color-text-disabled: var(--color-neutral-4); /* 無効時のUIテキストカラー */
}
